.hero-content-container {
  height: 100vh;
  width: 100vw;
  padding: 12vh 7vw;
  overflow: hidden;
  box-sizing: border-box;
  /* border: 1px solid red; */
  position: relative;
}
.hero-spinner-container {
  position: absolute;
  right: 6.5vw;
  bottom: 61vh;
  width: 150px;
  height: 150px;
  /* z-index: 15;
  border: 1px solid red; */
}
.hero-spinner-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-image-container {
  position: absolute;
  right: 10vw;
  bottom: 10vh;
  width: 45vw;
  height: 60vh;
  border-radius: 1.5vh;
  overflow: hidden;
  z-index: -10;
  box-shadow: 3px 9px 18px rgb(0 0 0 / 20%);
}
.hero-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero-content-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-content-wrapper .hero-content-header {
  font-family: 'Raleway', sans-serif;
  color: #02343f;
  font-size: 15vh;
  line-height: 120%;
  margin: 0;
  /* border: 1px solid red; */
}

.hero-content-wrapper p {
  margin-top: 6vh;
}

.hero-content-wrapper p {
  font-family: 'Questrial', sans-serif;
  line-height: 170%;
  font-weight: normal;
  margin-bottom: 0;
  font-size: 3vh;
  letter-spacing: 0px;
  width: 50%;
}
.scroll-row {
  position: relative;
  z-index: 1000;
  letter-spacing: 2px;
  color: #262626;

  /* border: 1px solid green; */
}
.scroll-row p::after {
  display: inline-block;
  content: '';
  border-top: 2px solid #02343f;
  width: 5vw;
  margin: 0 1rem;
  transform: translateY(-0.5rem);
}

/* Media Queries */

/* Media Query for tablets*/
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .hero-content-wrapper .hero-content-header {
    font-size: 10vh;
  }
  .hero-content-wrapper p {
    font-size: 2vh;
    width: 60%;
  }
  .hero-spinner-container {
    right: 4vw;
    bottom: 65vh;
  }
}

/* mobile media Queries */
@media screen and (max-width: 490px) {
  .hero-content-wrapper .hero-content-header {
    font-size: 7vh;
  }
  .hero-content-wrapper p {
    font-size: 2.5vh;
    width: 100%;
  }
  .scroll-row p {
    font-size: 2vh;
    text-transform: uppercase;
    opacity: 50%;
  }
  .hero-image-container {
    opacity: 0;
    box-shadow: none;
  }
  .hero-image-container img {
    opacity: 0;
  }
  .hero-spinner-container {
    position: absolute;
    right: 16vw;
    bottom: 15vh;
    width: 150px;
    height: 150px;
    /* z-index: 15;
  border: 1px solid red; */
  }
  .hero-spinner-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
