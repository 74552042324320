.about-content-container {
  height: 100vh;
  width: 100vw;
  padding: 0 8vw;
  /* border: 1px solid blue; */
  /* overflow: hidden; */
  box-sizing: border-box;
}
.about-content-wrapper {
  width: 100%;
  height: 100%;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  position: relative;
}
.about-heading {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  top: 3vh;
  font-weight: 700;
  color: #a3aaae;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}
.about-content-left {
  width: 100%;
  height: 100%;
  /* border: 1px solid gold; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-top: 10px;
  margin-left: 1.5vw;
}
.about-topline h1,
p {
  margin: 0;
}
.about-topline h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 4vh;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.about-topline p {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
  margin-bottom: 24px;
}
.bio {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
  margin-bottom: 24px;
}

.education h2 {
  margin: 0;
}
.education h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 3vh;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.education p {
  margin-bottom: 24px;
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
}
.education .education-desc {
  margin-bottom: 24px;
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
}
.skills h2 {
  margin: 0;
  margin-bottom: 24px;
}
.skills h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 3vh;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.skills .skills-icon-container {
  display: flex;
  width: 50%;
  /* border: 1px solid red; */
  overflow: hidden;
  justify-content: space-between;
}
.skills .icon {
  height: 40px;
  width: 40px;
  /* border: 1px solid red; */
}
.js {
  color: #8cc84b;
}
.react {
  color: #61dafb;
}
.angular {
  color: #c3002f;
}
.html {
  color: #e44d26;
}
.css {
  color: #2965f1;
}
.about-content-right {
  width: 100%;
  height: 100%;
  /* border: 1px solid silver; */
  display: flex;
}
.about-image-container-right {
  width: 100%;
  height: 50%;
  border-radius: 1.5vh;
  overflow: hidden;
  margin: 0 5vh;
  z-index: -10;
  box-shadow: 3px 9px 18px rgb(0 0 0 / 20%);
}
.about-image-container-right img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.about-image-container-left {
  width: 100%;
  height: 50%;
  align-self: center;
  margin-top: 30vh;
  border-radius: 1.5vh;
  overflow: hidden;
  z-index: -10;
  box-shadow: 3px 9px 18px rgb(0 0 0 / 20%);
}
.about-image-container-left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Media Queries */

/* Media Queries for tablets */
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .about-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .about-content-left {
    order: 2;
  }
  .about-image-container-left {
    margin-top: 20vh;
  }
  .about-image-container-right {
    margin: 0 2vw;
  }
  .about-content-right {
    width: 100%;
    height: 50%;
    order: 1;

    /* border: 1px solid green; */
  }
  .about-topline h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 4vh;
  }
  .about-topline p {
    font-size: 2vh;
  }
  .education .education-desc,
  .bio {
    font-size: 1.5vh;
  }
  .skills h2,
  .education h2 {
    font-size: 2vh;
  }
}

/* Media Queries for Phones  */
@media screen and (max-width: 490px) {
  .about-content-container {
    height: 150vh;
  }
  .about-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .about-content-left {
    width: 100%;
    height: 100%;
    order: 2;
  }
  .about-content-right {
    width: 100%;
    height: 50%;
    order: 1;

    /* border: 1px solid green; */
  }
  .about-image-container-left {
    /* border: 1px solid grey; */
    margin-top: 30vh;
    box-shadow: 0;
  }
  .about-image-container-right {
    /* border: 1px solid grey; */
    box-shadow: 0;
  }
  .about-image-container-right {
    margin: 0;
  }
  .skills .skills-icon-container {
    width: 100%;
  }
}
