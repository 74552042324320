/* importing google fonts Raleway + Questrial */
@import url('https://fonts.googleapis.com/css2?family=Questrial&family=Raleway:wght@300;400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* cursor: none; */
  /* setting fonts */
  font-family: 'Questrial', sans-serif;
  font-family: 'Raleway', sans-serif;
  background-color: #f2f2f2;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cdcfcf;
}
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 15px;
}
