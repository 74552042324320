.navbar-container {
  width: 100vw;
  position: fixed;
  margin: 0 auto;
  top: 5vh;
  padding: 0 4vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 30;
}
.navbar-container img {
  height: 8vh;
  cursor: pointer;
}
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: end;
  margin: 0;
}
.nav-links {
  display: flex;
  align-items: center;
}
.nav-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav-links ul li {
  font-size: 15px;
  font-weight: 500;
  padding: 6px;
  letter-spacing: 2px;
  color: #262626;
  cursor: pointer;
  /* border: 1px dashed red; */
}
.scroll-link.active {
  color: #02343f;
  font-weight: 700;
}
@media screen and (max-width: 490px) {
  .nav-links ul li {
    font-size: 10px;
  }
}
