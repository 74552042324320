@import url(https://fonts.googleapis.com/css2?family=Questrial&family=Raleway:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* importing google fonts Raleway + Questrial */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* cursor: none; */
  /* setting fonts */
  font-family: 'Questrial', sans-serif;
  font-family: 'Raleway', sans-serif;
  background-color: #f2f2f2;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cdcfcf;
}
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 15px;
}

.hero-content-container {
  height: 100vh;
  width: 100vw;
  padding: 12vh 7vw;
  overflow: hidden;
  box-sizing: border-box;
  /* border: 1px solid red; */
  position: relative;
}
.hero-spinner-container {
  position: absolute;
  right: 6.5vw;
  bottom: 61vh;
  width: 150px;
  height: 150px;
  /* z-index: 15;
  border: 1px solid red; */
}
.hero-spinner-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-image-container {
  position: absolute;
  right: 10vw;
  bottom: 10vh;
  width: 45vw;
  height: 60vh;
  border-radius: 1.5vh;
  overflow: hidden;
  z-index: -10;
  box-shadow: 3px 9px 18px rgb(0 0 0 / 20%);
}
.hero-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero-content-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 2vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.hero-content-wrapper .hero-content-header {
  font-family: 'Raleway', sans-serif;
  color: #02343f;
  font-size: 15vh;
  line-height: 120%;
  margin: 0;
  /* border: 1px solid red; */
}

.hero-content-wrapper p {
  margin-top: 6vh;
}

.hero-content-wrapper p {
  font-family: 'Questrial', sans-serif;
  line-height: 170%;
  font-weight: normal;
  margin-bottom: 0;
  font-size: 3vh;
  letter-spacing: 0px;
  width: 50%;
}
.scroll-row {
  position: relative;
  z-index: 1000;
  letter-spacing: 2px;
  color: #262626;

  /* border: 1px solid green; */
}
.scroll-row p::after {
  display: inline-block;
  content: '';
  border-top: 2px solid #02343f;
  width: 5vw;
  margin: 0 1rem;
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
}

/* Media Queries */

/* Media Query for tablets*/
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .hero-content-wrapper .hero-content-header {
    font-size: 10vh;
  }
  .hero-content-wrapper p {
    font-size: 2vh;
    width: 60%;
  }
  .hero-spinner-container {
    right: 4vw;
    bottom: 65vh;
  }
}

/* mobile media Queries */
@media screen and (max-width: 490px) {
  .hero-content-wrapper .hero-content-header {
    font-size: 7vh;
  }
  .hero-content-wrapper p {
    font-size: 2.5vh;
    width: 100%;
  }
  .scroll-row p {
    font-size: 2vh;
    text-transform: uppercase;
    opacity: 50%;
  }
  .hero-image-container {
    opacity: 0;
    box-shadow: none;
  }
  .hero-image-container img {
    opacity: 0;
  }
  .hero-spinner-container {
    position: absolute;
    right: 16vw;
    bottom: 15vh;
    width: 150px;
    height: 150px;
    /* z-index: 15;
  border: 1px solid red; */
  }
  .hero-spinner-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about-content-container {
  height: 100vh;
  width: 100vw;
  padding: 0 8vw;
  /* border: 1px solid blue; */
  /* overflow: hidden; */
  box-sizing: border-box;
}
.about-content-wrapper {
  width: 100%;
  height: 100%;
  /* border: 1px solid green; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* overflow: hidden; */
  position: relative;
}
.about-heading {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  top: 3vh;
  font-weight: 700;
  color: #a3aaae;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}
.about-content-left {
  width: 100%;
  height: 100%;
  /* border: 1px solid gold; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  margin-top: 10px;
  margin-left: 1.5vw;
}
.about-topline h1,
p {
  margin: 0;
}
.about-topline h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 4vh;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.about-topline p {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
  margin-bottom: 24px;
}
.bio {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
  margin-bottom: 24px;
}

.education h2 {
  margin: 0;
}
.education h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 3vh;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.education p {
  margin-bottom: 24px;
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
}
.education .education-desc {
  margin-bottom: 24px;
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
}
.skills h2 {
  margin: 0;
  margin-bottom: 24px;
}
.skills h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 3vh;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.skills .skills-icon-container {
  display: -webkit-flex;
  display: flex;
  width: 50%;
  /* border: 1px solid red; */
  overflow: hidden;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.skills .icon {
  height: 40px;
  width: 40px;
  /* border: 1px solid red; */
}
.js {
  color: #8cc84b;
}
.react {
  color: #61dafb;
}
.angular {
  color: #c3002f;
}
.html {
  color: #e44d26;
}
.css {
  color: #2965f1;
}
.about-content-right {
  width: 100%;
  height: 100%;
  /* border: 1px solid silver; */
  display: -webkit-flex;
  display: flex;
}
.about-image-container-right {
  width: 100%;
  height: 50%;
  border-radius: 1.5vh;
  overflow: hidden;
  margin: 0 5vh;
  z-index: -10;
  box-shadow: 3px 9px 18px rgb(0 0 0 / 20%);
}
.about-image-container-right img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.about-image-container-left {
  width: 100%;
  height: 50%;
  -webkit-align-self: center;
          align-self: center;
  margin-top: 30vh;
  border-radius: 1.5vh;
  overflow: hidden;
  z-index: -10;
  box-shadow: 3px 9px 18px rgb(0 0 0 / 20%);
}
.about-image-container-left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Media Queries */

/* Media Queries for tablets */
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .about-content-wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
  }
  .about-content-left {
    -webkit-order: 2;
            order: 2;
  }
  .about-image-container-left {
    margin-top: 20vh;
  }
  .about-image-container-right {
    margin: 0 2vw;
  }
  .about-content-right {
    width: 100%;
    height: 50%;
    -webkit-order: 1;
            order: 1;

    /* border: 1px solid green; */
  }
  .about-topline h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 4vh;
  }
  .about-topline p {
    font-size: 2vh;
  }
  .education .education-desc,
  .bio {
    font-size: 1.5vh;
  }
  .skills h2,
  .education h2 {
    font-size: 2vh;
  }
}

/* Media Queries for Phones  */
@media screen and (max-width: 490px) {
  .about-content-container {
    height: 150vh;
  }
  .about-content-wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden;
  }
  .about-content-left {
    width: 100%;
    height: 100%;
    -webkit-order: 2;
            order: 2;
  }
  .about-content-right {
    width: 100%;
    height: 50%;
    -webkit-order: 1;
            order: 1;

    /* border: 1px solid green; */
  }
  .about-image-container-left {
    /* border: 1px solid grey; */
    margin-top: 30vh;
    box-shadow: 0;
  }
  .about-image-container-right {
    /* border: 1px solid grey; */
    box-shadow: 0;
  }
  .about-image-container-right {
    margin: 0;
  }
  .skills .skills-icon-container {
    width: 100%;
  }
}

.showcase-content-container {
  height: 100vh;
  width: 100vw;
  padding: 0 8vw;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.showcase-content-wrapper {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* border: 1px solid red; */
  position: relative;
}
.showcase-heading {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  top: 10vh;
  font-weight: 700;
  color: #a3aaae;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}
.showcase-content-wrapper img {
  width: 45vw;
  height: 45vh;
  object-fit: cover;
  border-radius: 12px;
  margin-top: 5vh;
  box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 75%);
}
.showcase-content-details {
  width: 45%;
  /* margin-left: 15%; */
  margin-left: 10%;
  margin-top: 8vh;
}
.showcase-content-details h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 5vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.showcase-content-details h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.showcase-content-details h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #363636;
}
.showcase-content-details p {
  margin-top: 2vh;
}
.showcase-content-details {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
}
.showcase-link {
  text-decoration: underline;
  margin: 3vh 0;
}
.showcase-content-icon-container {
  display: -webkit-flex;
  display: flex;
  width: 50%;
  margin-top: 2vh;
  /* border: 1px solid red; */
  overflow: hidden;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.showcase-content-icon-container .showcase-icon {
  height: 30px;
  width: 30px;
  /* border: 1px solid red; */
}
.showcase-counter-container {
  font-family: 'Raleway', sans-serif;
  font-size: 4vh;
  font-weight: 300;
  padding-top: 5vh;
  text-align: end;
}
.showcase-counter-container::before {
  display: inline-block;
  content: '';
  border-top: 2px solid black;
  width: 5vw;
  margin: 0 1rem;
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
}

/* Showcase Section Reverse Order */
.showcase-content-container-2 {
  height: 100vh;
  width: 100vw;
  padding: 0 8vw;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.showcase-content-wrapper-2 {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* border: 1px solid red; */
  position: relative;
}
.showcase-heading-2 {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  top: 0vh;
  left: -1vw;
  font-weight: 700;
  color: #a3aaae;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}
.showcase-content-wrapper-2 img {
  width: 45vw;
  height: 45vh;
  object-fit: cover;
  border-radius: 12px;
  margin-top: 5vh;
  box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 75%);
}
.showcase-content-details-2 {
  width: 45%;
  /* margin-left: 15%; */
  margin-right: 10%;
  margin-top: 5vh;
}
.showcase-content-details-2 h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 5vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.showcase-content-details-2 h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.showcase-content-details-2 h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #363636;
}
.showcase-content-details-2 p {
  margin-top: 2vh;
}
.showcase-content-details-2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
}
.mongo {
  color: #10aa50;
}
.pure-js {
  color: #f7e018;
}

.showcase-heading-3 {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  text-align: end;
  top: 0vh;
  left: -1vw;
  font-weight: 700;
  color: #a3aaae;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}

/* Media Queries */

/* Media Queries for tablets */
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .showcase-content-wrapper {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
  }
  .showcase-content-details {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 0;
    margin-top: 2vh;
    font-size: 1.5vh;
  }
  .showcase-content-wrapper img {
    /* border: 1px solid red; */
    width: 100%;
    height: 30vh;
    object-fit: fill;
    margin-top: 15vh;
    box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 55%);
  }
  .showcase-content-icon-container .showcase-icon {
    height: 40px;
    width: 40px;
    /* border: 1px solid red; */
  }
  .showcase-counter-container {
    font-size: 3vh;
    font-weight: 300;
    padding-top: 4vh;
    text-align: end;
  }
  .showcase-counter-container::before {
    width: 10vw;
  }
  .showcase-content-wrapper-2 {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
  }
  .showcase-content-details-2 {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 0;
    margin-top: 2vh;
    margin-right: 0;
    font-size: 1.5vh;
    -webkit-order: 2;
            order: 2;
  }
  .showcase-content-wrapper-2 img {
    /* border: 1px solid red; */
    width: 100%;
    height: 30vh;
    margin-top: 15vh;
    -webkit-order: 1;
            order: 1;
    box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 55%);
  }
  .showcase-content-icon-container-2 .showcase-icon {
    height: 40px;
    width: 40px;
    /* border: 1px solid red; */
  }
  .showcase-counter-container-2 {
    font-size: 3vh;
    font-weight: 300;
    padding-top: 4vh;
    text-align: end;
  }
  .showcase-counter-container-2::before {
    width: 10vw;
  }
}
/* Media Queries for phones */
@media screen and (max-width: 490px) {
  .showcase-content-container {
    height: 100vh;
    width: 100vw;
  }
  .showcase-heading {
    top: 2vh;
  }
  .showcase-content-wrapper {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* border: 1px solid red; */
    position: relative;
    /* overflow: hidden; */
  }
  .showcase-content-wrapper img {
    /* border: 1px solid red; */
    width: 100%;
    height: 30vh;
    margin-top: 7vh;
    object-fit: fill;
    box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 55%);
  }
  .showcase-content-details {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 0;
    margin-top: 1vh;
  }
  .showcase-counter-container {
    font-size: 3vh;
  }
  .showcase-counter-container::before {
    width: 15vw;
  }
  .showcase-content-wrapper-2 {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
  }
  .showcase-content-details-2 {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 0;
    margin-top: 1vh;
    -webkit-order: 2;
            order: 2;
  }
  .showcase-content-wrapper-2 img {
    /* border: 1px solid red; */
    width: 100%;
    height: 30vh;
    margin-top: 7vh;
    object-fit: fill;
    box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 55%);
  }
  .showcase-counter-container-2::before {
    width: 15vw;
  }
  .showcase-content-icon-container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-top: 2vh;
    /* border: 1px solid red; */
    overflow: hidden;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

.contact-content-container {
  height: 100vh;
  width: 100vw;
  padding: 0 8vw;
  /* border: 1px solid blue; */
  box-sizing: border-box;
}
.contact-content-wrapper {
  width: 100%;
  height: 100%;
  /* border: 1px dashed red; */
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.contact-heading {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  top: 0;
  font-weight: 700;
  color: #a3aaa3;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}
.contact-content-left {
  /* border: 1px solid green; */
  background-image: url('https://images.unsplash.com/photo-1483794344563-d27a8d18014e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80');
  background-size: cover;
  text-align: center;
  background-color: #f2f2f2;
  border-radius: 1.5vh;
  padding: 5vh;
  box-shadow: 3px 9px 18px rgb(0 0 0 / 20%);
  margin-top: 15vh;
  display: -webkit-flex;
  display: flex;
}

.contact-content-left h1 {
  font-size: 10vh;
  color: #02343f;
}
.contact-content-left h2 {
  color: #02343f;
}
.contact-content-left p {
  font-size: 3vh;
  color: #262626;
}
.contact-content-left a {
  font-weight: bold;
  color: #262626;
}
.contact-content-socials {
  font-size: 3vh;
}
.contact-content-socials-container .icons {
  height: 40px;
  width: 40px;
  margin: 0 10px;
}
.dribble {
  color: #e55189;
}
.linkedin {
  color: #225982;
}

/* media queries */

/* Media Query for tablet */
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .contact-heading {
    top: 14vh;
  }
  .contact-content-wrapper {
    /* border: 1px dashed red; */
    overflow: hidden;
  }
  .contact-content-left {
    width: 90%;
    height: 60%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .contact-content-left h1 {
    font-size: 7vh;
  }
  .contact-content-left p {
    font-size: 2vh;
  }
  .contact-content-left h2 {
    font-size: 2vh;
  }
}

/* Media Query for phones */
@media screen and (max-width: 490px) {
  .contact-heading {
    top: 23vh;
  }
  .contact-content-wrapper {
    /* border: 1px dashed red; */
    overflow: hidden;
  }
  .contact-content-left {
    width: 90%;
    height: 50%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .contact-content-left h1 {
    font-size: 4vh;
  }
  .contact-content-left p {
    font-size: 2vh;
  }
  .contact-content-left h2 {
    font-size: 2vh;
  }
  .contact-content-socials-container .icons {
    height: 30px;
    width: 30px;
  }
}

.navbar-container {
  width: 100vw;
  position: fixed;
  margin: 0 auto;
  top: 5vh;
  padding: 0 4vw;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  z-index: 30;
}
.navbar-container img {
  height: 8vh;
  cursor: pointer;
}
.nav-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  list-style: none;
  text-align: end;
  margin: 0;
}
.nav-links {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.nav-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav-links ul li {
  font-size: 15px;
  font-weight: 500;
  padding: 6px;
  letter-spacing: 2px;
  color: #262626;
  cursor: pointer;
  /* border: 1px dashed red; */
}
.scroll-link.active {
  color: #02343f;
  font-weight: 700;
}
@media screen and (max-width: 490px) {
  .nav-links ul li {
    font-size: 10px;
  }
}

.cursor {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #262626;
  z-index: 2000;
  opacity: 0.7;
  pointer-events: none;
}
.follower {
  position: fixed;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #262626;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 2000;
  pointer-events: none;
}

@media (hover: none) and (pointer: coarse) {
  .cursor {
    visibility: hidden;
  }
  .follower {
    visibility: hidden;
  }
}

.loader-container {
  height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
  visibility: hidden;
}
.loader-content-wrapper {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loader-content-wrapper img {
  height: 25vh;
  width: 25vw;
}
.loader-content-wrapper h1 {
  font-size: 4vh;
  font-weight: 400;
  color: #0d0d0d;
  letter-spacing: 5px;
}
.loading-dots-container {
  display: -webkit-flex;
  display: flex;
  width: 5vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.loading-dots {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #cdcfcf;
}

/* Media Queries */
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .loading-dots-container {
    display: -webkit-flex;
    display: flex;
    width: 10vw;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}
/* Media Queries */
@media screen and (max-width: 490px) {
  .loader-content-wrapper h1 {
    font-size: 3vh;
    font-weight: 400;
    color: #0d0d0d;
    letter-spacing: 5px;
  }
  .loading-dots-container {
    display: -webkit-flex;
    display: flex;
    width: 20vw;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

