.contact-content-container {
  height: 100vh;
  width: 100vw;
  padding: 0 8vw;
  /* border: 1px solid blue; */
  box-sizing: border-box;
}
.contact-content-wrapper {
  width: 100%;
  height: 100%;
  /* border: 1px dashed red; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-heading {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  top: 0;
  font-weight: 700;
  color: #a3aaa3;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}
.contact-content-left {
  /* border: 1px solid green; */
  background-image: url('https://images.unsplash.com/photo-1483794344563-d27a8d18014e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80');
  background-size: cover;
  text-align: center;
  background-color: #f2f2f2;
  border-radius: 1.5vh;
  padding: 5vh;
  box-shadow: 3px 9px 18px rgb(0 0 0 / 20%);
  margin-top: 15vh;
  display: flex;
}

.contact-content-left h1 {
  font-size: 10vh;
  color: #02343f;
}
.contact-content-left h2 {
  color: #02343f;
}
.contact-content-left p {
  font-size: 3vh;
  color: #262626;
}
.contact-content-left a {
  font-weight: bold;
  color: #262626;
}
.contact-content-socials {
  font-size: 3vh;
}
.contact-content-socials-container .icons {
  height: 40px;
  width: 40px;
  margin: 0 10px;
}
.dribble {
  color: #e55189;
}
.linkedin {
  color: #225982;
}

/* media queries */

/* Media Query for tablet */
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .contact-heading {
    top: 14vh;
  }
  .contact-content-wrapper {
    /* border: 1px dashed red; */
    overflow: hidden;
  }
  .contact-content-left {
    width: 90%;
    height: 60%;
    justify-content: center;
    align-items: center;
  }
  .contact-content-left h1 {
    font-size: 7vh;
  }
  .contact-content-left p {
    font-size: 2vh;
  }
  .contact-content-left h2 {
    font-size: 2vh;
  }
}

/* Media Query for phones */
@media screen and (max-width: 490px) {
  .contact-heading {
    top: 23vh;
  }
  .contact-content-wrapper {
    /* border: 1px dashed red; */
    overflow: hidden;
  }
  .contact-content-left {
    width: 90%;
    height: 50%;
    justify-content: center;
    align-items: center;
  }
  .contact-content-left h1 {
    font-size: 4vh;
  }
  .contact-content-left p {
    font-size: 2vh;
  }
  .contact-content-left h2 {
    font-size: 2vh;
  }
  .contact-content-socials-container .icons {
    height: 30px;
    width: 30px;
  }
}
