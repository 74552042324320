.loader-container {
  height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
  visibility: hidden;
}
.loader-content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader-content-wrapper img {
  height: 25vh;
  width: 25vw;
}
.loader-content-wrapper h1 {
  font-size: 4vh;
  font-weight: 400;
  color: #0d0d0d;
  letter-spacing: 5px;
}
.loading-dots-container {
  display: flex;
  width: 5vw;
  justify-content: space-between;
}
.loading-dots {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #cdcfcf;
}

/* Media Queries */
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .loading-dots-container {
    display: flex;
    width: 10vw;
    justify-content: space-between;
  }
}
/* Media Queries */
@media screen and (max-width: 490px) {
  .loader-content-wrapper h1 {
    font-size: 3vh;
    font-weight: 400;
    color: #0d0d0d;
    letter-spacing: 5px;
  }
  .loading-dots-container {
    display: flex;
    width: 20vw;
    justify-content: space-between;
  }
}
