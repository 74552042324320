.showcase-content-container {
  height: 100vh;
  width: 100vw;
  padding: 0 8vw;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.showcase-content-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  position: relative;
}
.showcase-heading {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  top: 10vh;
  font-weight: 700;
  color: #a3aaae;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}
.showcase-content-wrapper img {
  width: 45vw;
  height: 45vh;
  object-fit: cover;
  border-radius: 12px;
  margin-top: 5vh;
  box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 75%);
}
.showcase-content-details {
  width: 45%;
  /* margin-left: 15%; */
  margin-left: 10%;
  margin-top: 8vh;
}
.showcase-content-details h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 5vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.showcase-content-details h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.showcase-content-details h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #363636;
}
.showcase-content-details p {
  margin-top: 2vh;
}
.showcase-content-details {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
}
.showcase-link {
  text-decoration: underline;
  margin: 3vh 0;
}
.showcase-content-icon-container {
  display: flex;
  width: 50%;
  margin-top: 2vh;
  /* border: 1px solid red; */
  overflow: hidden;
  justify-content: space-between;
}
.showcase-content-icon-container .showcase-icon {
  height: 30px;
  width: 30px;
  /* border: 1px solid red; */
}
.showcase-counter-container {
  font-family: 'Raleway', sans-serif;
  font-size: 4vh;
  font-weight: 300;
  padding-top: 5vh;
  text-align: end;
}
.showcase-counter-container::before {
  display: inline-block;
  content: '';
  border-top: 2px solid black;
  width: 5vw;
  margin: 0 1rem;
  transform: translateY(-0.5rem);
}

/* Showcase Section Reverse Order */
.showcase-content-container-2 {
  height: 100vh;
  width: 100vw;
  padding: 0 8vw;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
.showcase-content-wrapper-2 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  position: relative;
}
.showcase-heading-2 {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  top: 0vh;
  left: -1vw;
  font-weight: 700;
  color: #a3aaae;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}
.showcase-content-wrapper-2 img {
  width: 45vw;
  height: 45vh;
  object-fit: cover;
  border-radius: 12px;
  margin-top: 5vh;
  box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 75%);
}
.showcase-content-details-2 {
  width: 45%;
  /* margin-left: 15%; */
  margin-right: 10%;
  margin-top: 5vh;
}
.showcase-content-details-2 h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 5vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.showcase-content-details-2 h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #02343f;
}
.showcase-content-details-2 h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  margin: 0;
  font-weight: bold;
  line-height: 130%;
  color: #363636;
}
.showcase-content-details-2 p {
  margin-top: 2vh;
}
.showcase-content-details-2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2vh;
  line-height: 200%;
  color: #0d0d0d;
}
.mongo {
  color: #10aa50;
}
.pure-js {
  color: #f7e018;
}

.showcase-heading-3 {
  font-family: 'Questrial', sans-serif;
  position: absolute;
  letter-spacing: 2px;
  text-align: end;
  top: 0vh;
  left: -1vw;
  font-weight: 700;
  color: #a3aaae;
  opacity: 0.15;
  z-index: -100;
  font-size: 15vw;
}

/* Media Queries */

/* Media Queries for tablets */
@media screen and (max-width: 1080px) and (orientation: portrait) {
  .showcase-content-wrapper {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .showcase-content-details {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 0;
    margin-top: 2vh;
    font-size: 1.5vh;
  }
  .showcase-content-wrapper img {
    /* border: 1px solid red; */
    width: 100%;
    height: 30vh;
    object-fit: fill;
    margin-top: 15vh;
    box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 55%);
  }
  .showcase-content-icon-container .showcase-icon {
    height: 40px;
    width: 40px;
    /* border: 1px solid red; */
  }
  .showcase-counter-container {
    font-size: 3vh;
    font-weight: 300;
    padding-top: 4vh;
    text-align: end;
  }
  .showcase-counter-container::before {
    width: 10vw;
  }
  .showcase-content-wrapper-2 {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .showcase-content-details-2 {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 0;
    margin-top: 2vh;
    margin-right: 0;
    font-size: 1.5vh;
    order: 2;
  }
  .showcase-content-wrapper-2 img {
    /* border: 1px solid red; */
    width: 100%;
    height: 30vh;
    margin-top: 15vh;
    order: 1;
    box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 55%);
  }
  .showcase-content-icon-container-2 .showcase-icon {
    height: 40px;
    width: 40px;
    /* border: 1px solid red; */
  }
  .showcase-counter-container-2 {
    font-size: 3vh;
    font-weight: 300;
    padding-top: 4vh;
    text-align: end;
  }
  .showcase-counter-container-2::before {
    width: 10vw;
  }
}
/* Media Queries for phones */
@media screen and (max-width: 490px) {
  .showcase-content-container {
    height: 100vh;
    width: 100vw;
  }
  .showcase-heading {
    top: 2vh;
  }
  .showcase-content-wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid red; */
    position: relative;
    /* overflow: hidden; */
  }
  .showcase-content-wrapper img {
    /* border: 1px solid red; */
    width: 100%;
    height: 30vh;
    margin-top: 7vh;
    object-fit: fill;
    box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 55%);
  }
  .showcase-content-details {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 0;
    margin-top: 1vh;
  }
  .showcase-counter-container {
    font-size: 3vh;
  }
  .showcase-counter-container::before {
    width: 15vw;
  }
  .showcase-content-wrapper-2 {
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .showcase-content-details-2 {
    /* border: 1px solid red; */
    width: 100%;
    margin-left: 0;
    margin-top: 1vh;
    order: 2;
  }
  .showcase-content-wrapper-2 img {
    /* border: 1px solid red; */
    width: 100%;
    height: 30vh;
    margin-top: 7vh;
    object-fit: fill;
    box-shadow: 0px 6px 40px 0px rgb(0 0 0 / 55%);
  }
  .showcase-counter-container-2::before {
    width: 15vw;
  }
  .showcase-content-icon-container {
    display: flex;
    width: 100%;
    margin-top: 2vh;
    /* border: 1px solid red; */
    overflow: hidden;
    justify-content: space-between;
  }
}
