.cursor {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #262626;
  z-index: 2000;
  opacity: 0.7;
  pointer-events: none;
}
.follower {
  position: fixed;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #262626;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 2000;
  pointer-events: none;
}

@media (hover: none) and (pointer: coarse) {
  .cursor {
    visibility: hidden;
  }
  .follower {
    visibility: hidden;
  }
}
